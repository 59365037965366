/* body {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  @apply text-[16px];
}

/* NAVIGATION STYLES */
.nav-links {
  @apply ml-auto hidden sm:flex gap-x-6 lg:gap-x-9;
}

.nav-link {
  @apply py-[10px] px-[15px] rounded-lg cursor-pointer;
  border: solid 1px transparent;
}

.nav-link:hover {
  @apply text-primary border border-primary;
}

/* INPUT STYLES */
.custom__input {
  @apply w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1];
}

.custom__label {
  @apply text-textBlack text-[14px] sm:text-[16px] mb-[10px] block;
}

.password__eye {
  @apply absolute right-4 top-[50%] translate-y-[-50%] cursor-pointer;
}

.label {
  @apply font-medium text-[18px] text-textBlack;
}

/* OUTLINE BUTTONS */
.button {
  @apply text-[16px];
}

.filled-button {
  @apply bg-primary text-textWhite duration-300 hover:bg-white disabled:hover:bg-primary disabled:hover:text-white hover:border-primary hover:border hover:text-primary uppercase rounded-lg py-[10px] px-[30px] font-semibold text-[16px];
}

.outline-button {
  @apply border border-primary uppercase text-primary duration-300 hover:bg-primary hover:text-textWhite rounded-lg py-[10px] px-[30px] font-semibold text-[16px];
}

nav .filled-button,
nav .outline-button {
  font-size: 14px;
}

.diabled-button {
  @apply bg-[#CECECE] text-textWhite uppercase font-semibold text-[16px];
}

.outline-button__load-more {
  @apply outline-button !text-textWhite !border-white;
}

/* TEXT STYLES */
.h1 {
  @apply font-bold text-[36px] text-textBlack;
}

.h2 {
  @apply font-semibold text-[28px] text-textBlack;
}

.h3 {
  @apply font-medium text-[22px] text-textBlack;
}

.body-description {
  @apply text-[16px];
}

.subheadings {
  @apply text-[18px] font-medium text-black/60;
}

.filter-section .subheadings,
.filter-section .body-description {
  font-size: 14px;
}

.subheading-20 {
  @apply font-medium text-[20px] text-textBlack;
}

.gradient-text {
  @apply bg-custom-gradient text-transparent bg-clip-text;
}

/* FILE UPLOAD */
.file-container {
  @apply border-[3px] rounded-lg mt-[6.7px] pt-[21px] pb-4 border-dashed flex flex-col px-[39px] items-center;
}

.file-container__title {
  @apply font-semibold mt-[17.8px] mb-[10px] text-black text-[16px] text-center;
}

.file-container__button {
  @apply py-[5px] px-[13px] cursor-pointer font-medium text-textBlack text-[10px] border rounded-[4px] border-[#CBCBCB];
}

.file-container__info {
  @apply mb-3 text-[9px];
}

/* MODAL STYLES */
.modal__container {
  box-shadow: 0px 4px 8px 0px #4682b44d;
  @apply bg-white max-w-[90vw] w-[526px] border-none outline-none absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] rounded-[20px] p-8 sm:p-[35px];
}

.modal__title {
  @apply h2 text-textBlack font-semibold mb-[5px];
}

.modal__subtitle {
  @apply block mb-[30px] font-medium text-black/60 text-xl;
}

.modal__action {
  @apply rounded-lg px-[30px] py-[10px] bg-primary uppercase block mx-auto text-textWhite mt-[30px] text-[16px];
}

/* SHADOWS */
.card-shadow {
  box-shadow: 0px 4px 8px 0px #4682b44d;

  /* box-shadow: 0px 4px 12px 0px #4682b433; */
}

.filter-section .scroll-section::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.filter-section .scroll-section {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.filter-section .card-shadow {
  box-shadow: 0px 1px 3px 0px #4682b44d;
  margin: 0 10px;
  border-radius: 2px 2px 5px 5px;
}

.explore-shadow {}

.img-background {
  @apply absolute top-0 left-0 z-[-1] w-full h-full object-cover;
}

.section__title {
  @apply text-[36px] font-semibold;
}

/* Material UI Customization */
.css-1gnojum-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab:hover {
  @apply !bg-white;
}

/* POPULAR CITIES SECTION */
.city-container {
  /* @apply w-[calc(100%-32px)] sm:min-w-[calc(calc(100%-130px)/3)] */
  @apply mr-10;
}

.city-container__name {
  @apply text-[24px] sm:text-[28px];
}

.city-container .img-overlay {
  @apply bg-primary/75 rounded-[14px] z-20 absolute top-0 left-0 h-full w-full hidden items-center justify-center;
}

.city-container:hover .city-container__name {
  @apply text-primary;
}

.city-container:hover .img-overlay {
  @apply flex;
}

/* PROPERTY CARD */
.property-card {
  @apply sm:w-[363px] sm:max-w-full w-full card-shadow rounded-xl relative h-fit overflow-hidden;
}

.property-card__img-container {
  @apply h-[266px] relative overflow-hidden w-full rounded-t-[10px];
}

.property-card__name {
  @apply h3 cursor-pointer;
}

.property-card__address {
  @apply body-description mt-[6px] mb-1;
}

.property-card__img {
  @apply object-cover w-full h-full rounded-t-[10px];
}

.property-card__pill {
  @apply bg-[#92AB94] text-textWhite py-[1.3px] px-[6px] rounded-[3px] text-[10px];
}

.PhoneInputInput {
  @apply !outline-none !border-none;
}

/* FOOTER */
.footer-link {
  @apply hover:translate-x-3 hover:opacity-75
}

/* Hide scrollbars */
.scrollbar-hidden {
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Edge */
}

/* PAYMENT MODAL */
.payment-modal {
  background-color: white;
  padding: 50px 33px;
  width: 526px;
  max-width: 90%;
  text-align: center;
  position: absolute;
  overflow-y: auto;
  border: none;
  outline: none;
  border-radius: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  @apply top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] max-h-[85vh] md:max-h-[90vh]
}

.payment-modal__close {
  margin-left: auto;
  margin-top: -20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.payment-modal__title {
  text-decoration: underline;
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  line-height: 27px;
}

.payment-modal__price {
  display: flex;
  flex-direction: column;
  margin-top: 27px;
  margin-bottom: 18px;
}

.payment-modal__label {
  font-size: 14px;
  display: block;
  margin-bottom: 6px;
  @apply text-textBlack;
}

.payment-modal__price .dollar {
  font-family: Poppins;
  font-size: 50px;
  font-weight: 400;
  line-height: 75px;
}

.payment-modal__price .naira {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
}

.payment-modal__field {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.payment-modal__value {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.payment-modal__info {
  font-style: italic;
  margin-bottom: 23px;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader2 {
  width: 48px;
  height: 48px;
  border: 5px solid #066D0E;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* HTML: <div class="loader"></div> */
.payment-loader {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  margin: 40px 0;
  background: #066D0E;
  box-shadow: 0 0 0 0 #066d0f49;
  animation: l1 1s infinite;
}

@keyframes l1 {
  100% {
    box-shadow: 0 0 0 30px #066d0f4f
  }
}