@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,italic&subset=latin,latin-ext");

body,
html {
  font-family: Poppins !important;
  margin: 0;
  min-height: 100vh;
  overflow-x: auto;
  overflow-y: auto;
  color: #091540;
  font-size: 12px;
}

._mobile {
  display: none !important;
}

input.MuiInputBase-input,
.MuiSelect-select.MuiInputBase-input {
  /* margin-top: 8px;
  margin-bottom: 12px; */
  padding: 14px 16px;
}

.MuiFormLabel-root.MuiInputLabel-root {
  top: 6px;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink,
.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
  top: 9px;
}

.form-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  align-items: flex-end;
}
.form-row.single-line {
  flex-direction: column;
  align-items: normal;
}

.form-input {
  flex: 1;
  min-width: 200px;
}

.form-input._error p {
  color: red;
}

.form-input .MuiInputBase-root {
  margin-top: 8px;
  margin-bottom: 12px;
}
.single-line .form-input .MuiInputBase-root {
  margin-top: 0;
  margin-bottom: 0;
}

.form-input fieldset {
  border: solid 1px #e4e4e7;
  border-radius: 8px;
}

.form-input._error fieldset {
  border: solid 2px red;
}

.form-input p {
  line-height: 27px;
  /* font-weight: 500; */
}

._not-visible {
  visibility: hidden;
}

._avatar {
  width: 30px;
  height: 30px;
  background-color: #eeeeee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: content-box;
}

.page-table .MuiPaper-root.MuiPaper-elevation {
  box-shadow: none;
}

.section-table .MuiPaper-root.MuiPaper-elevation {
  box-shadow: none;
  border: solid 1px #e4e4e7;
  border-radius: 8px;
  margin-bottom: 0;
}

.dashboard-layout.seller .section-table .MuiPaper-root.MuiPaper-elevation {
  box-shadow: 0px 3.62px 7.24px 0px #4682b44d;
  /* box-shadow: 0px 3.62px 10.86px 0px #4682B433; */
  border: none;
  border-radius: 8px;
  margin-bottom: 0;
}

.dashboard-layout.seller .MuiTableCell-root.MuiTableCell-head:not(.MuiTableCell-paddingCheckbox,
  .MuiTableCell-paddingNone) {
  padding: 11px;
}

.card-table .MuiPaper-root.MuiPaper-elevation {
  box-shadow: 0px 3.62px 7.24px 0px #4682b44d;
  /* box-shadow: 0px 3.62px 10.86px 0px #4682B433; */
  /* border: solid 1px #E4E4E7; */
  border-radius: 8px;
  margin-bottom: 0;
}

.card-table .MuiToolbar-root.MuiToolbar-gutters {
  background-color: #f9f9f9;
}

.card-table .MuiTableCell-root.MuiTableCell-body {
  padding: 20px;
}

.card-table tbody tr.MuiTableRow-root {
  border-top: none;
}

thead.MuiTableHead-root {
  background-color: #f9f9f9;
}

tbody tr.MuiTableRow-root {
  border-top: solid #f1f1f1 2px;
}

.MuiTableCell-root.MuiTableCell-head {
  font-size: 15px;
}

.MuiTableCell-root.MuiTableCell-body {
  font-size: 14px;
}

.custom-table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 25px;
  margin-top: 18px;
  overflow: hidden;
}

.custom-table-pagination ._direction {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 14px;
  border: solid 2px #cecece;
  border-radius: 8px;
  box-shadow: 0px 1.04px 2.07px 0px #1018280d;
}

.seller .custom-table-pagination ._direction {
  padding: 8px;
  border-radius: 50%;
  border-color: #444444;
}

.seller .custom-table-pagination ._direction p {
  display: none;
}

.custom-table-pagination ._direction._disabled {
  cursor: default;
  opacity: 0.4;
  filter: saturate(1);
}

.custom-table-pagination ._direction:not(._disabled):hover {
  cursor: pointer;
}

.custom-table-pagination ._numbers {
  /* min-width: 400px;
  width: 500px; */
    /* max-width: 199px; */
  max-width: 500px;
  overflow: auto;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.custom-table-pagination ._number {
  padding: 10px 17px;
  border-radius: 8px;
  user-select: none;
}

.custom-table-pagination ._number._active {
  background-color: #e8ffe9;
}

.custom-table-pagination ._number._active p {
  color: #066d0e;
  font-weight: 600;
}

.custom-table-pagination ._number:not(._disabled):hover {
  color: #066d0e;
  background-color: #e8ffe9;
  cursor: pointer;
}

.custom-table-pagination ._number._active {
  background-color: #e8ffe9;
}

a.hover_underline,
.hover_underline {
  text-decoration: none;
}

a.hover_underline:hover,
.hover_underline:hover {
  text-decoration: underline;
}

.dashboard-layout {}

.dashboard-page-title {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.dashboard-page-title .flank-right {
  margin-left: auto;
  color: #36363680;
  margin-right: 50px;
}

.dashboard-header {
  height: 94px;
  box-shadow: 0px 4px 8px 0px #4682b44d;
  /* box-shadow: 0px 4px 12px 0px #4682B433; */

  padding: 17px 50px;
  padding-left: 290px;
  display: flex;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
}

.profile-page input:disabled.Mui-disabled {
  background-color: #eee;
  -webkit-text-fill-color: unset;
  color: #000;
}

.profile-page .profile-header {
  position: relative;
  display: flex;
  height: 38px;
  align-items: center;
  margin: 0 -20px;
}

.profile-page .profile-badge {
  position: absolute;
  right: 0px;
  /* top: 20px; */
  background-color: rgb(64, 165, 64);
  color: rgb(255, 255, 255);
  padding: 10px 50px;
  border-radius: 25px 3px 3px 25px;
}

.profile-page .profile-badge._pending {
  background-color: #a0a03e;
}

.profile-page .profile-badge._rejected {
  background-color: #c72525;
}

.MuiButtonBase-root.MuiButton-root {
  border-radius: 8px;
  line-height: 21px;
  font-size: 14.5px;
  padding: 9px 32px;
  min-width: 177px;
  box-sizing: border-box;
  height: 40px;
}

/* Remove custom material ui global style from date picker */
.MuiPickersLayout-root .MuiButtonBase-root.MuiButton-root{
 padding: 6px 10px;
 min-width: 10px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
  border-width: 2px;
  font-weight: 600;
  background-color: #f8f8f8;
}

.custom-button.MuiButtonBase-root.MuiButton-root {
  padding: 9px 15px;
  min-width: unset;
  text-transform: none;
  color: #363636;
  display: flex;
  gap: 4px;
}

._mini-btns .MuiButtonBase-root.MuiButton-root {
  font-size: 11.5px;
  min-width: 73px;
  padding: 7px 12px;
  line-height: 17px;
  height: unset;
  border-radius: 5px;
}

._mini-btns {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 5px;
}

.dashboard-header .MuiButton-root.MuiButton-contained {
  padding: 10px 30px;
  box-shadow: none;
}

.card-group {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.info-card {
  box-shadow: 0px 3.62px 7.24px 0px #4682b44d;
  /* box-shadow: 0px 3.62px 10.86px 0px #4682B433; */
  padding: 22px 51px 22px 27px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.info-card-header {
  border-bottom: solid 1px #cecece;
  padding-bottom: 7px;
  font-size: 16px;
}

.info-card-body {
  /* display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap; 
  row-gap: 25px; */
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 25px 15px;
}

.info-card ._section {
  display: flex;
  flex-direction: column;
  gap: 7px;
  max-width: 240px;
  min-width: 140px;
  word-break: break-word;
}

.info-card ._section._large {
  max-width: unset;
}

.info-card ._section ._data {
  display: flex;
  /* height: 42px; */
  gap: 7px;
}

.info-card ._section._large ._data {
  height: unset;
}

.info-card ._section ._title p {
  color: #5e5e5e;
  font-weight: 500;
}

.info-card ._section ._data p {
  color: #363636;
  /* font-size: 16px;
  font-weight: 500; */
  line-height: 24px;
}

.info-card ._section ul {
  margin: 0;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style: disc;
}

.dashboard-header-search {
  width: 430px;
  max-width: 430px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 24px;
  border-radius: 50px;
  box-shadow: 0px 1px 3px 1px #4682b426;
  /* box-shadow: 0px 1px 2px 0px #87CEEB4D; */
  box-sizing: border-box;
}

.central-search {
  max-width: 688px;
  min-width: 300px;
  height: 44px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 24px;
  margin-bottom: 24px;
  border-radius: 50px;
  box-sizing: border-box;
}

.seller .central-search {
  margin-bottom: 0;
  flex: 1;
}

.page-central .icon-holder {
  min-width: 44px;
  width: 44px;
  height: 44px;
  background-color: #e8ffe9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 3px 1px #4682b426;
  /* box-shadow: 0px 1px 2px 0px #87CEEB4D; */
}

.dashboard-header-search fieldset,
.dashboard-header-search input,
.central-search fieldset,
.central-search input {
  border: none;
  font-size: 16px;
}

.dashboard-core {
  /* display: flex; */
}

.dashboard-sidenav {
  width: 240px;
  min-width: 240px;
}

.dashboard-sidenav-inner {
  position: fixed;
  top: 0;
  background-color: #fff;
  width: inherit;
  height: 100%;
  /* background-color: pink; */
  box-shadow: 0px 1px 3px 1px #00000026;
  /* box-shadow: 0px 1px 2px 0px #0000004D; */
  display: flex;
  flex-direction: column;
  z-index: 1001;
}

.dashboard-logo-block {
  height: 147px;
  min-height: 147px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-divider {
  height: 1px;
  border-top: solid 1px #f2f4f7;
  width: 96%;
  margin: 0 2%;
}

.dashboard-nav-items {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.dashboard-nav-item {
  padding: 10px 16px;
  display: flex;
  gap: 13.5px;
  align-items: center;
  text-decoration: none;
  color: #344054;
}

.dashboard-nav-item:hover,
.dashboard-nav-item.active {
  color: #066d0e;
}

.dashboard-nav-item.active p {
  font-weight: 500;
}

.dashboard-nav-item img {
  filter: brightness(0) saturate(100%);
}

.dashboard-nav-item:hover img,
.dashboard-nav-item.active img,
._green-img {
  filter: brightness(0) saturate(100%) invert(29%) sepia(22%) saturate(3861%) hue-rotate(92deg) brightness(92%) contrast(96%);
}

.dashboard-nav-item .icon-box {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #f2f4f7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-main-wrapper {
  width: 100%;
  max-width: 100%;
  padding: 40px 30px;
  padding-left: 270px;
  box-sizing: border-box;
}

.admin .dashboard-main-wrapper._no-padding,
.seller .dashboard-main-wrapper._no-padding {
  padding-top: 0;
  padding-right: 0;
  padding-left: 240px;
  box-sizing: border-box;
}

.seller .page-title,
.admin ._no-padding .page-title {
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #e4e4e7;
  padding: 0 24px;
}

.seller .page-title ._avatar {
  width: 50px;
  height: 50px;
}

.seller .page-header {
  text-align: center;
  margin-bottom: 45px;
}

.page-body.width-700 {
  width: 700px;
}

.seller .page-body {
  padding: 0 76px;
}

.seller .page-body._features {
  display: grid;
  grid-template-columns: auto auto;
  gap: 40px;
}

.seller .page-body._features .feature-group {
  display: grid;
  grid-template-columns: auto auto;
  border: solid 1px #e4e4e7;
  border-radius: 8px;
  padding: 15px;
  gap: 10px 20px;
  margin-top: 10px;
}

.seller .page-body._features .feature-item {
  display: flex;
  gap: 10px;
  align-items: center;
}

.seller .dasboard-base {
  width: 100%;
}

.seller .list-group-box {
  padding: 0;
  margin-bottom: 20px;
}

.seller .list-group-box h5 {
  font-weight: 400;
}

.seller .list-group-box._error h5 {
  color: red;
}

.seller .list-group {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
  flex-wrap: wrap;
  gap: 30px 7%;
}

.seller .upload-box {
  /* border: dashed 2px #949494; */
  border-radius: 14px;
  width: 100%;
  max-height: 300px;
  height: 14vw;
  min-height: 192px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 2vw;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='grey' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 14px;
  margin-bottom: 40px;
  box-sizing: content-box;
}

.seller .upload-box._uploaded {
  padding: 20px;
  height: unset;
  background-image: none;
  border: solid 1px #e4e4e7;
  width: 100%;
  display: flex;
  align-items: center;
}

.seller .upload-box .upload-captions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.no-records {
  padding: 15px;
  display: inline-block;
  font-style: italic;
  opacity: 0.5;
}

span.coming-soon {
  background-color: white;
  color: red;
  font-size: 8px;
  vertical-align: middle;
  font-weight: 400;
  letter-spacing: 1px;
  /* border: solid 1px; */
  border-radius: 3px;
  padding: 0 2px;
  margin-left: 10px;
  box-shadow: 0 0 11px #f008;
}

.upload-box .MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
  border-color: #cbcbcb;
  color: black;
  border-width: 2px;
  font-weight: 500;
  background-color: transparent;
}

.seller .uploaded-holder-outer {
  width: 14vw;
}

.seller .upload-holder {
  background-color: #f9f9f9;
  border-radius: 14px 14px 0 0;
  /* flex: 1 1; */
  max-width: 250px;
  min-width: 100px;
  /* gap: 20px; */
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14vw;
  height: 13vw;
  /* flex-wrap: wrap; */
}

.seller .upload-holder img {
  width: 6.5vw;
  min-width: 40px;
  max-width: 94px;
}

.dashboard-main {
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.dashboard-main .page-central {
  min-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1;
}

.buyer-dashboard-main .page-central {
  width: 100%;
}

.seller .dashboard-main .page-central {
  gap: 20px;
}

.dashboard-main .page-side {
  width: 30vw;
  max-width: 410px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.seller .dashboard-main .page-side {
  width: 309px;
  min-width: unset;
  gap: 10px;
}

.dasboard-base {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px 31px;
  padding: 36px;
  flex-wrap: wrap;
}

.page-side .side-card {
  border: solid 1px #e4e4e7;
  border-radius: 10px;
  clip-path: border-box;
}

.seller .page-side .side-card {
  background-color: #f8f8f8;
}

.page-side .side-card._shadow {
  border: none;
  box-shadow: 0px 3.62px 7.24px 0px #4682b44d;
  clip-path: unset;
}

.seller .page-side .side-card._shadow {
  /* padding: 12px 24px; */
}

.page-side .side-card.cardInfo {
  border: none;
  box-shadow: 0px 3.62px 7.24px 0px #4682b44d;
  /* box-shadow: 0px 3.62px 10.86px 0px #4682B433; */
  border-radius: 10px;
  clip-path: unset;
}

.page-side .side-card-header {
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 10px 10px 0 0;
  height: 52px;
  box-sizing: border-box;
  color: #000000;
}

.page-side .cardInfo .side-card-header {
  height: 62px;
  justify-content: flex-start;
  gap: 10px;
}

.page-side .cardInfo .side-card-header h5 {
  font-weight: 400;
}

.side-card .mini-card {
  box-shadow: 0px 3.62px 7.24px 0px #4682b44d;
  border-radius: 10px;
  background-color: #f9f9f9;
  margin-bottom: 15px;
  padding: 42px 39px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.side-card .MuiTable-root {
  min-width: unset;
}

.side-card .MuiTableCell-root.MuiTableCell-head {
  font-size: 14px;
  font-weight: 400;
  padding: 16px;
}

.icon-circle-grey {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

._grey {
  filter: brightness(0) saturate(100%);
  opacity: 0.8;
}

._flex {
  display: flex;
  align-items: center;
}

._flex._col {
  flex-direction: column;
  align-items: flex-start;
}

.icon-circle-grey._small {
  width: 36px;
  height: 36px;
}

.dashboard-main .page-side ._row {
  gap: 10px;
  display: flex;
  padding: 18px 24px;
  align-items: center;
}

.side-card-main {
  padding-top: 6px;
}

.side-card-main ._preview {
  padding: 0 30px;
  margin-bottom: 5px;
}

.side-card-main ._preview ._group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 5px 0;
}

.side-card-main ._preview ._thumbnail {
  min-width: 80px;
  /* width: 105px; */
  min-height: 65px;
  border-radius: 5px;
  background-color: #eee;
  width: 30%;
  height: 6.2vw;
  max-height: 89px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-card-main ._preview ._thumbnail img,
.side-card-main ._preview ._thumbnail video {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.side-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.side-card-footer ._right {
  text-transform: uppercase;
  margin-left: auto;
}

.push-notification-banner {
  background-color: #e8ffe9;
  padding: 12px;
  height: 84px;
  border-radius: 8px;
  box-sizing: border-box;
}

.floating-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0 0 10px 0px #366d36;
}

.floating-button svg {
  font-size: 40px;
  color: #ffffff;
}

.badge {
  min-width: 104px;
  padding: 2px 8px;
  border-radius: 17px;
  font-size: 12.5px;
  line-height: 18.64px;
  background-color: #eaeaea;
  color: #363636;
  font-weight: 500;
  /* height: 23px; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.badge._success {
  background-color: #e8ffe9;
  color: #066d0e;
}

.badge._error {
  background-color: #ffeae8;
  color: #cd0101;
}

.progress-line {
  display: flex;
  align-items: center;
}

.progress-tick {
  display: flex;
  justify-content: center;
  /* text-align: center; */
  align-items: center;
  width: 30px;
}

.progress-label {
  padding-left: 10px;
}

.progress-label .sub-text p {
  color: #cecece;
}

.progress-line._sub {
  min-height: 35px;
  border-left: solid 1px #bbb;
  margin-left: 14px;
  align-items: flex-start;
  padding-top: 5px;
  padding-left: 14px;
}

.progress-line._sub._no-line {
  border-left: none;
  padding-left: 15px;
}

.report-cards {
  display: flex;
  gap: 21px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.report-card {
  border: solid 1px #e4e4e7;
  border-radius: 13px;
  padding: 12px;
  /* min-width: 224px;
  width: 31.5%; */
  flex: 1;
  max-width: 280px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 109px;
}

.seller .report-card {
  height: 148px;
}

.seller .report-card.verification-status {
  background-color: #e8ffe9;
}

.seller .report-card.verification-status ._text {
  color: #084b0b;
  text-transform: capitalize;
}

.seller .report-card.verification-status._pending {
  background-color: #fffce8;
}

.seller .report-card.verification-status._pending ._text {
  color: #867726;
}

.seller .report-card.verification-status._rejected {
  background-color: #ffe8e8;
}

.seller .report-card.verification-status._rejected ._text {
  color: #550707;
}

.seller .report-card ._top h5 {
  color: #686868;
  font-weight: 300;
}

.seller .report-card.verification-status ._top h5 {
  /* color: #066d0e; */
  font-weight: 400;
}

.seller .report-card>div {
  justify-content: flex-start;
}

.report-card._shadow {
  border: none;
  box-shadow: 0px 3.62px 7.24px 0px #4682b44d;
  clip-path: unset;
}

.report-card>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.push-notification-banner {
  background-color: #e8ffe9;
  padding: 12px;
  height: 84px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border: solid 1px #ccffce;
}

.push-notification-banner ._left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.push-notification-banner ._right {
  display: flex;
  align-items: center;
}

label.browse-btn {
  border: solid 1px #666;
  border-radius: 5px;
  padding: 10px 30px;
  font-size: 14px;
  cursor: pointer;
}

.MuiToolbar-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
  background-color: #f9f9f9;
  box-shadow: 0px 1px 3px 1px #4682b426;
  /* box-shadow: 0px 1px 2px 0px #87CEEB4D; */
  border: none;
  margin-bottom: 10px;
}

.MuiToolbar-root .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  text-align: center;
  background-color: white;
  padding: 4px 8px;
  margin: 8px 8px 8px 8px;
  border: none;
  min-width: 50px;
}

.MuiToolbar-root fieldset {
  border: none;
}

.dashboard-layout.seller .MuiToolbar-root.MuiToolbar-gutters {
  min-height: 45px;
}

.dashboard-layout.seller .MuiTypography-root.MuiTypography-h4 {
  font-size: 14px;
}

.page-modal {
  position: relative;
  background-color: #ffffff;
  width: 90%;
  margin: auto;
  height: 93%;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}

.dashboard-layout .listing-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  border: solid 1px #e4e4e7;
  border-radius: 7px;
  flex-wrap: wrap;
  max-width: 1318px;
}

.single-listing {
  border: solid 1px transparent;
  box-sizing: border-box;
}

.single-listing:hover {
  border: solid 1px green;
  cursor: pointer;
}

.dashboard-layout .listing-group .single-listing {
  flex: 1;
  min-width: 200px;
  max-width: 340px;
}

.buyer .dashboard-layout .listing-group .single-listing {
  max-width: 425px;
}

.dashboard-layout .listing-group .single-listing a {
  cursor: default;
}

.dashboard-layout.admin .single-listing .swiper-pagination.swiper-pagination-bullets {
  bottom: 5px;
}

.dashboard-layout .single-listing .top-btns {
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 10px;
  right: 10px;
  display: flex;
  align-items: center;
}

.dashboard-layout .single-listing .top-btns button {
  background-color: #066d0ecc;
  border-radius: 2.5px;
  padding: 2px 7px;
  margin-right: 5px;
}

.buyer .dashboard-layout .single-listing .top-btns button {
  border-radius: 4px;
  padding: 4.5px 15px;
  margin: 5px 0 5px 5px;
}

.dashboard-layout .single-listing .top-btns button p {
  font-size: 8px;
  color: #ffffff;
  font-weight: 300;
  line-height: 12px;
}

.buyer .dashboard-layout .single-listing .top-btns button p {
  font-size: 14px;
  line-height: 16px;
}

.dashboard-layout .single-listing .swipe-buttons {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 50%;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}

.dashboard-layout .single-listing .swipe-buttons>div {
  cursor: pointer;
}

.dashboard-layout .single-listing .rating-verified,
.dashboard-layout .single-listing .rating-group,
.dashboard-layout .single-listing .is-verified {
  display: flex;
  align-items: center;
}

.dashboard-layout .single-listing .rating-verified {
  margin-bottom: 10px;
}

.dashboard-layout .single-listing .is-verified {
  margin-left: 8px;
  padding-left: 8px;
  border-left: solid 1px #ccc;
  height: 12px;
  gap: 2px;
}

.buyer .dashboard-layout .single-listing .is-verified {
  height: 20px;
}

.dashboard-layout .single-listing .is-verified p {
  color: #1570ef;
  font-size: 8.5px;
  font-weight: 300;
}

.dashboard-layout .single-listing .listing-details {
  padding: 10px 13px;
  margin-top: auto;
}

.buyer .dashboard-layout .single-listing .listing-details {
  padding: 20px;
}

.dashboard-layout .single-listing .listing-developer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.dashboard-layout .single-listing .listing-developer p {
  font-size: 13px;
}

.buyer .dashboard-layout .single-listing .listing-developer p {
  font-size: 22px;
  line-height: 33px;
  margin-top: 10px;
}

.dashboard-layout .single-listing .listing-date {
  background-color: #363636CC;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1px 13px;
  color: #F8F8F8;
  font-weight: 300;
  font-size: 9px;
  border-top-left-radius: 4px;
  z-index: 1;
}

.buyer .dashboard-layout .single-listing .listing-date {
  display: block;
}

.dashboard-layout .single-listing .listing-developer>div {
  display: flex;
  align-items: center;
  gap: 2px;
}

.dashboard-layout .single-listing .listing-developer>div {
  display: flex;
  align-items: center;
}

.dashboard-layout .single-listing .listing-developer>div p,
.dashboard-layout .single-listing .more-info p {
  font-size: 10px;
  font-weight: 300;
}

.dashboard-layout .single-listing .more-info {
  display: flex;
  flex-direction: column;
}

.buyer .dashboard-layout .single-listing .more-info {
  gap: 5px;
  margin: 5px 0;
}

.dashboard-layout .single-listing .more-info p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
}

.buyer .dashboard-layout .single-listing .more-info p {
  font-size: 16px;
  line-height: 24px;
}

.dashboard-layout .single-listing .more-info>p>span,
.dashboard-layout .single-listing .utility-price p>span {
  color: #cccccc;
}

.dashboard-layout .single-listing .listing-documents {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 3px;
}

.buyer .dashboard-layout .single-listing .listing-documents {
  gap: 4px 6px;
}

.dashboard-layout .single-listing .listing-documents p {
  font-size: 8px;
  white-space: nowrap;
  background-color: #86be86;
  padding: 0px 3px;
  color: #ffffff;
  font-weight: 300;
  margin: 0;
  line-height: 12px;
  border-radius: 2px;
}

.buyer .dashboard-layout .single-listing .listing-documents p {
  font-size: 11px;
  background-color: #92AB94;
  padding: 3px 6px;
  text-shadow: 1px 1px #666666;
}

.dashboard-layout .single-listing .utility-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.dashboard-layout .single-listing .utility-price p {
  font-size: 10px;
  font-weight: 300;
}

.buyer .dashboard-layout .single-listing .utility-price p {
  font-size: 16px;
}

.dashboard-layout .single-listing .utility-price ._price {
  display: flex;
  align-items: center;
}

.dashboard-layout .single-listing .utility-price ._price p {
  font-weight: 500;
}

.dashboard-layout .single-listing .listing-action-btns {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #ddd;
  padding-top: 9px;
  margin-top: 3px;
}

.buyer .dashboard-layout .single-listing .listing-action-btns {
  padding: 15px 0 0px;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 10px;
}

.dashboard-layout .single-listing .listing-action-btns .MuiButtonBase-root.MuiButton-root {
  height: unset;
  padding: 6px 15px;
  width: 100px;
  min-width: unset;
  font-size: 10px;
  line-height: 12px;
  border-radius: 5px;
  border: solid 1px;
}

.buyer .dashboard-layout .single-listing .listing-action-btns .MuiButtonBase-root.MuiButton-root {
  padding: 9px 31px;
  font-size: 16px;
  line-height: 24px;
  width: unset;
}

.modal-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #eeeeee88;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.loader {
  border: 3px solid #83a987;
  border-top: 3px solid #066d0e;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
}

.buyer-dashboard-searchbar {
  margin-top: 94px;
  padding: 17px 50px;
  /* border: solid 1px; */
  background-color: #F8F8F8;
  z-index: 1;
  /* box-shadow: 0px 1px 3px 1px blue; */
  /* box-shadow: 0px 1px 2px 0px red; */
  box-shadow: 0px 1px 3px 1px #4682B426;
  /* box-shadow: 0px 1px 2px 0px #87CEEB4D; */
}

.buyer-dashboard-searchbar input {
  background-color: transparent;
  font-weight: 300;
  outline: none;
}

.buyer-dashboard-main {
  display: flex;
  gap: 30px;
  padding: 30px 46px;
  position: relative;
  background-color: #F8F8F8;
}

.buyer-dashboard-sidenav {
  width: 240px;
  min-width: 240px;
  background-color: yellow;
  position: sticky;
  top: 120px;
  height: fit-content;
  background-color: #ffffff;
  border: solid 1px #EAECF0;
  border-radius: 8px;
  flex: unset;
}

.buyer-dashboard-core {
  flex: 1;
}

.buyer-dashboard-core ._page-title {
  font-size: 22px;
}

input._input {
  border: solid 1px #e4e4e7;
  border-radius: 8px;
  width: 100%;
}

input._input:hover {
  border-color: #666;
}

.error-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: solid 2px #d53636;
  border-width: 2px 0;
  padding: 10px 30px;
  background-color: #faf5dd;
  color: #9d0505;
  flex-wrap: wrap;
}

.close-error-banner {
  position: absolute;
  right: 10px;
  top: 9px;
  cursor: pointer;
}

.btn-width-300 {
  width: 300px;
}

.carouse {
  width: 358px;
  height: 100px;
}

.buyer-dashboard-nav {
  position: fixed;
  min-height: 94px;
  width: 100%;
  z-index: 100;
  background-color: rgb(255, 255, 255);
}

.currency-switch {
  position: relative;
  align-self: center;
}

.currency-switch .currency-selected {
  display: flex;
  align-items: center;
  align-self: center;
  border: solid 1px;
  padding: 5px;
  height: 29px;
  font-size: 12px;
  font-weight: 300;
  border-radius: 5px;
  cursor: pointer;
}

.currency-switch ._list {
  position: absolute;
  background-color: #666;
  width: 100%;
  color: #fff;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 2px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 10000;
}

.currency-switch ._list ._each:hover {
  cursor: pointer;
  color: #ccc;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1000px) {}

@media (max-width: 600px) {
  ._pc {
    display: none !important;
  }

  ._mobile {
    display: block !important;
  }

  .nav-logo {
    zoom: 0.6;
  }

  .throw-right {
    margin-left: auto;
  }

  .MuiTypography-root.MuiTypography-h4 {
    font-size: 15px;
  }

  .MuiTypography-root.MuiTypography-h5,
  .MuiTypography-root.MuiTypography-body1,
  .MuiTableCell-root.MuiTableCell-body {
    font-size: 14px;
  }

  .MuiTableCell-root.MuiTableCell-head {
    font-size: 14px;
  }

  .badge {
    min-width: 95px;
    font-size: 11.5px;
  }

  .dashboard-header {
    height: unset;
    padding: 5px 20px;
    justify-content: flex-end;
  }

  .dashboard-header .MuiButtonBase-root.MuiButton-root {
    min-width: unset;
    padding: 9px 8px;
  }

  .dashboard-header-search fieldset,
  .dashboard-header-search input,
  .central-search fieldset,
  .central-search input,
  input {
    font-size: 15px;
  }

  .dashboard-header-search {
    width: unset;
    height: 40px;
    padding: 14px 8px;
    box-shadow: none;
  }

  .dashboard-sidenav {
    display: none;
  }

  .dashboard-main-wrapper {
    padding: 0;
  }

  .seller .dashboard-main-wrapper {
    padding-left: 0;
  }

  .seller .page-body {
    padding: 0;
  }

  .dashboard-page-title {
    margin: 0;
    padding: 30px;
    padding-bottom: 0;
  }

  .page-modal .dashboard-page-title {
    flex-direction: column;
    align-items: flex-start;
  }

  .page-modal .dashboard-page-title span {
    margin-left: 0 !important;
  }

  .dashboard-main {
    width: 100%;
    max-width: 100%;
    padding: 40px 10px;
    box-sizing: border-box;
    padding-top: 80px;
  }

  .seller ._no-padding .dashboard-main {
    padding: 0 10px;
  }

  .seller .dashboard-main .page-side {
    width: 100%;
  }

  .seller ._no-padding .page-title {
    height: 60px;
    padding: 10px;
  }

  .seller ._no-padding .page-title ._avatar {
    width: 30px;
    height: 30px;
  }

  .seller .list-group-box button {
    padding: 6px 16px;
    font-size: 12px;
    min-width: unset;
    height: unset;
  }

  .seller .dasboard-base {
    padding: 0 0 20px;
  }

  .profile-page .profile-badge {
    top: 97px;
    padding: 10px 30px
  }

  .dashboard-main .page-central {
    min-width: unset;
    max-width: 100%;
    gap: 30px;
  }

  .dashboard-main .page-side {
    width: unset;
    min-width: unset;
    gap: 30px;
  }

  .dashboard-main .page-side ._row {
    padding: 18px 10px;
  }

  .report-card {
    max-width: unset;
    min-width: 200px;
  }

  .info-card-body {
    grid-template-columns: auto auto;
  }

  .page-modal {
    padding: 0;
  }

  .page-modal .dashboard-main {
    padding-top: 20px;
  }

  .page-modal .dashboard-page-title .flank-right {
    margin-right: 0;
  }

  .page-modal .dashboard-main .page-side {
    max-width: 100%;
  }

  .page-modal .info-card {
    padding: 15px;
  }

  .page-modal .info-card-body {
    grid-template-columns: auto;
  }

  .page-modal .info-card ._section {
    max-width: unset;
  }

  .table-title>* {
    display: inline-flex;
    margin-left: 0 !important;
  }

  .custom-table-pagination {
    padding: 14px 10px;
  }

  .page-table .custom-table-pagination {
    padding: 14px 0;
  }

  .custom-table-pagination ._direction p {
    display: none;
  }

  .custom-table-pagination ._numbers {
    min-width: unset;
  }

  .dasboard-base .MuiButtonBase-root.MuiButton-root {
    width: 100%;
  }

  .dashboard-layout .listing-group .single-listing {
    max-width: unset;
  }

  .btn-width-300 {
    width: 100%;
  }

  .seller .upload-box._uploaded {
    flex-direction: column;
  }

  .dashboard-layout .single-listing .listing-documents p {
    font-size: 10px;
    background-color: #b0d1b0;
    padding: 1px 4px;
  }

  .seller .dashboard-main-wrapper._no-padding {
    padding-left: 0;
  }

  .seller .page-body._features {
    grid-template-columns: unset;
  }

  .seller .page-body._features .feature-group {
    padding: 10px 5px;
    gap: 10px;
  }

  .seller .list-group-box {
    padding: 0 0;
  }

  .seller .list-group {
    gap: 10px;
  }

  .seller .uploaded-holder-outer {
    width: unset;
  }
  .seller .upload-holder {
    flex: 1;
    min-width: 100px;
    max-width: unset;
    width: unset;
    height: 29vw;
  }

  .seller .upload-box {
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    min-height: 150px;
  }

  .seller .upload-box .upload-captions {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 0;
  }

  .seller .upload-box .upload-captions .MuiTypography-root.MuiTypography-body1 {
    font-size: 11px;
  }

  .page-table .MuiToolbar-root {
    padding: 0 !important;
  }

  .page-table #tableTitle .badge {
    min-width: unset;
    margin-left: 5px !important;
  }

  .buyer-dashboard-main {
    padding: 20px 15px;
  }

  .buyer-dashboard-core {
    margin-top: 0;
  }

  .buyer-dashboard-core ._page-title {
    font-size: 18px;
  }

  .feature-list {
    width: 78vw;
  }

  .page-table .MuiToolbar-root.table-toolbar {
    margin-left: 10px;
  }

  .buyer .page-table .MuiToolbar-root.table-toolbar {
    margin-top: 90px;
  }

  .buyer-dashboard-nav {
    min-height: 67px;
    max-height: 67px;
  }

  .buyer-dashboard-searchbar {
    margin-top: 67px;
    padding: 17px;
  }

  .swiper-wrapper {
    max-width: 90vw;
  }

}